var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"home_screen d-flex"},[_c('div',{staticClass:"list-body"},[_c('v-row',{staticStyle:{"min-width":"240px","padding-top":"20px"}},[(_vm.requests && _vm.requests.length)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"cursor-pointer",attrs:{"type":"warning","dense":""},on:{"click":function($event){_vm.requestDialog = true}}},[_vm._v(" You have "+_vm._s(_vm.requests.length)+" order requests. ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","md":"6","height":"100%"}},[_c('v-card',{staticStyle:{"height":"100%"}},[_c('v-card-title',{staticClass:"d-flex flex-row",staticStyle:{"justify-content":"space-between"}},[_c('div',[_vm._v("Deliver")]),_c('v-btn',{attrs:{"rounded":"","outlined":""},on:{"click":_vm.onDistribute}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-barcode")]),_vm._v("Distribute")],1)],1),_c('v-card-text',[_vm._v(" Recent deliveries ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.distributions,"headers":_vm.distributionHeader,"items-per-page":5,"show-expand":"","expanded":_vm.expanded,"item-key":"_id"},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('editable-avatar',{attrs:{"image":item.to.image,"editable":false,"size":30}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.to.first_name + " " + (item.to.last_name ? item.to.last_name : ""))+" ")]}},{key:"item.client_id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.to.client_id)+" ")]}},{key:"item.food",fn:function(ref){
var item = ref.item;
return [_c('div',[(item.fruits)?_c('div',[_vm._v("Fruits: "+_vm._s(item.fruits))]):_vm._e(),(item.vegetables)?_c('div',[_vm._v(" Vegetables: "+_vm._s(item.vegetables)+" ")]):_vm._e(),(item.grains)?_c('div',[_vm._v("Grains: "+_vm._s(item.grains))]):_vm._e(),(item.protein)?_c('div',[_vm._v("Protein: "+_vm._s(item.protein))]):_vm._e(),(item.dairy)?_c('div',[_vm._v("Dairy: "+_vm._s(item.dairy))]):_vm._e()])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getDateFromIndex(item._id))+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":_vm.distributionHeader.length}},[_c('v-data-table',{staticClass:"px-3",attrs:{"headers":_vm.subHeaders,"items":item.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"max-width":"100px"},domProps:{"innerHTML":_vm._s(item.food.name)}})]}},{key:"item.serving_size",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(
                            item.food.serving_size + ' ' + item.food.unit
                          )}})]}},{key:"item.meals",fn:function(ref){
                          var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(item.food.meals)}})]}},{key:"item.image",fn:function(ref){
                          var item = ref.item;
return [(item.food)?_c('div',{staticClass:"ma-3",staticStyle:{"width":"70px","max-width":"70px"}},[_c('v-img',{attrs:{"src":item.food.image
                                ? item.food.image
                                : _vm.placeholderImage,"width":70,"height":50}})],1):_vm._e()]}},{key:"item.nutritions",fn:function(ref){
                                var item = ref.item;
return [(item.food.fruits)?_c('div',[_vm._v(" Fruits: "+_vm._s(item.food.fruits)+" ")]):_vm._e(),(item.food.vegetables)?_c('div',[_vm._v(" Vegetables: "+_vm._s(item.food.vegetables)+" ")]):_vm._e(),(item.food.grains)?_c('div',[_vm._v(" Grains: "+_vm._s(item.food.grains)+" ")]):_vm._e(),(item.food.protein)?_c('div',[_vm._v(" Protein: "+_vm._s(item.food.protein)+" ")]):_vm._e(),(item.food.dairy)?_c('div',[_vm._v(" Dairy: "+_vm._s(item.food.dairy)+" ")]):_vm._e()]}},{key:"item.date",fn:function(ref){
                                var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.formatTime(item.createdAt))}})]}}],null,true)})],1)]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"6","height":"100%"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_vm._v("Events")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.goToEventHome}},[_vm._v("View All")])],1),_c('v-card-text',_vm._l((_vm.events),function(event){return _c('div',{key:event._id,staticClass:"mb-4 d-flex"},[_c('div',[_c('v-img',{attrs:{"src":event.cover,"height":"70","width":"70"}})],1),_c('div',{staticStyle:{"flex-grow":"1","padding-left":"10px","padding-right":"10px"}},[_c('div',{staticClass:"url-button",staticStyle:{"overflow":"hidden","text-overflow":"ellipsis","display":"-webkit-box","-webkit-line-clamp":"1","line-clamp":"1","-webkit-box-orient":"vertical","font-family":"'Poppins-SemiBold'","color":"#482684"},on:{"click":function($event){$event.stopPropagation();return _vm.goToEventDetails(event._id)},"mousedown":function($event){$event.stopPropagation();}}},[_vm._v(" "+_vm._s(event.title)+" ")]),_c('div',{staticClass:"limited-text"},[_vm._v(" "+_vm._s(event.text)+" ")])]),_c('div',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.getDate(event.createdAt))+" ")])])}),0)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"6","height":"100%"}},[_c('v-card',{attrs:{"height":"100%"}},[_c('v-card-title',{staticClass:"d-flex justify-between"},[_c('div',[_vm._v("Foods")]),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.getFoodBarcodePDF}},[_vm._v("Get Summary")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.balances,"headers":_vm.balanceHeader,"items-per-page":3,"footer-props":{
                  'items-per-page-options': [3, 5, 10, 15, -1],
                }},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
                var item = ref.item;
return [(item.food)?_c('div',{staticClass:"ma-3",staticStyle:{"width":"70px","max-width":"70px"}},[_c('v-img',{attrs:{"src":item.food.image ? item.food.image : _vm.placeholderImage,"width":70,"height":50}})],1):_vm._e()]}},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.food ? item.food.name : "")+" ")]}},{key:"item.serving_size",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.food.serving_size)+" "+_vm._s(item.food.unit)+" ")]}},{key:"item.meals",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.food.meals)+" ")]}},{key:"item.amount",fn:function(ref){
                var item = ref.item;
return [(item.editing)?_c('div',[_c('v-text-field',{model:{value:(item.amount),callback:function ($$v) {_vm.$set(item, "amount", $$v)},expression:"item.amount"}})],1):_c('div',[_vm._v(" "+_vm._s(item.amount)+" ")])]}},{key:"item.nutritions",fn:function(ref){
                var item = ref.item;
return [(item.food.fruits)?_c('div',[_vm._v(" Fruits: "+_vm._s(item.food.fruits)+" ")]):_vm._e(),(item.food.vegetables)?_c('div',[_vm._v(" Vegetables: "+_vm._s(item.food.vegetables)+" ")]):_vm._e(),(item.food.grains)?_c('div',[_vm._v(" Grains: "+_vm._s(item.food.grains)+" ")]):_vm._e(),(item.food.protein)?_c('div',[_vm._v(" Protein: "+_vm._s(item.food.protein)+" ")]):_vm._e(),(item.food.dairy)?_c('div',[_vm._v(" Dairy: "+_vm._s(item.food.dairy)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticStyle:{"width":"120px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onEditBalance(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(item.editing ? "mdi-check" : "mdi-pencil")+" ")])],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.onDeleteBalance(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)},[_vm._v(" Delete ")])],1)]}}],null,true)})],1)],1)],1),_c('v-col',{attrs:{"cols":"6","md":"6","height":"100%"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Freezer ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"items":_vm.freezers,"headers":_vm.freezerHeader,"items-per-page":5},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
                var item = ref.item;
return [_c('editable-avatar',{attrs:{"image":item.image,"editable":false,"size":30}})]}},{key:"item.name",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" ")]}},{key:"item.serving_size",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.food.serving_size)+" "+_vm._s(item.food.unit)+" ")]}}],null,true)})],1)],1),_c('v-card',{staticClass:"mt-6",on:{"click":_vm.goToChat}},[_c('v-card-title',[_vm._v(" Chat ")])],1)],1)],1)],1)]),_c('bottom-bar'),(_vm.pdfDialog)?_c('FoodPDFSummary',{ref:"barcode"}):_vm._e(),_c('confirm-delete-dialog',{attrs:{"deleteDialog":_vm.deleteDialog,"onClose":_vm.onCloseDialog,"onConfirm":_vm.onConfirmDelete}}),(_vm.requestDialog)?_c('client-order-requests-dialog',{attrs:{"dialog":_vm.requestDialog,"onClose":_vm.onCloseDialog,"onSelected":_vm.onSelectedRequest}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }