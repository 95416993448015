<template>
  <div>
    <div class="home_screen d-flex">
      <div class="list-body">
        <v-row style="min-width: 240px; padding-top: 20px">
          <v-col cols="12" v-if="requests && requests.length">
            <v-alert
              type="warning"
              dense
              @click="requestDialog = true"
              class="cursor-pointer"
            >
              You have {{ requests.length }} order requests.
            </v-alert>
          </v-col>
          <v-col cols="12" md="6" height="100%">
            <v-card style="height: 100%">
              <v-card-title
                class="d-flex flex-row"
                style="justify-content: space-between;"
              >
                <div>Deliver</div>
                <v-btn @click="onDistribute" rounded outlined
                  ><v-icon class="mr-2">mdi-barcode</v-icon>Distribute</v-btn
                >
              </v-card-title>
              <v-card-text>
                Recent deliveries
              </v-card-text>
              <v-card-text>
                <v-data-table
                  :items="distributions"
                  :headers="distributionHeader"
                  :items-per-page="5"
                  show-expand
                  :expanded.sync="expanded"
                  item-key="_id"
                >
                  <template v-slot:[`item.image`]="{ item }">
                    <editable-avatar
                      :image="item.to.image"
                      :editable="false"
                      :size="30"
                    />
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    {{
                      item.to.first_name +
                        " " +
                        (item.to.last_name ? item.to.last_name : "")
                    }}
                  </template>
                  <template v-slot:[`item.client_id`]="{ item }">
                    {{ item.to.client_id }}
                  </template>
                  <template v-slot:[`item.food`]="{ item }">
                    <div>
                      <!-- <div v-if="item.meals">Meals: {{ item.meals }}</div> -->
                      <div v-if="item.fruits">Fruits: {{ item.fruits }}</div>
                      <div v-if="item.vegetables">
                        Vegetables: {{ item.vegetables }}
                      </div>
                      <div v-if="item.grains">Grains: {{ item.grains }}</div>
                      <div v-if="item.protein">Protein: {{ item.protein }}</div>
                      <div v-if="item.dairy">Dairy: {{ item.dairy }}</div>
                    </div>
                  </template>
                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ getDateFromIndex(item._id) }}
                  </template>
                  <template v-slot:expanded-item="{ item }">
                    <td :colspan="distributionHeader.length" class="px-0">
                      <v-data-table
                        :headers="subHeaders"
                        :items="item.items"
                        hide-default-footer
                        class="px-3"
                      >
                        <template v-slot:[`item.name`]="{ item }">
                          <div
                            v-html="item.food.name"
                            style="max-width: 100px"
                          />
                        </template>
                        <template v-slot:[`item.serving_size`]="{ item }">
                          <div
                            v-html="
                              item.food.serving_size + ' ' + item.food.unit
                            "
                          />
                        </template>
                        <template v-slot:[`item.meals`]="{ item }">
                          <div v-html="item.food.meals" />
                        </template>
                        <template v-slot:[`item.image`]="{ item }">
                          <div
                            class="ma-3"
                            style="width: 70px; max-width: 70px;"
                            v-if="item.food"
                          >
                            <v-img
                              :src="
                                item.food.image
                                  ? item.food.image
                                  : placeholderImage
                              "
                              :width="70"
                              :height="50"
                            />
                          </div>
                        </template>
                        <template v-slot:[`item.nutritions`]="{ item }">
                          <div v-if="item.food.fruits">
                            Fruits: {{ item.food.fruits }}
                          </div>
                          <div v-if="item.food.vegetables">
                            Vegetables: {{ item.food.vegetables }}
                          </div>
                          <div v-if="item.food.grains">
                            Grains: {{ item.food.grains }}
                          </div>
                          <div v-if="item.food.protein">
                            Protein: {{ item.food.protein }}
                          </div>
                          <div v-if="item.food.dairy">
                            Dairy: {{ item.food.dairy }}
                          </div>
                        </template>
                        <template v-slot:[`item.date`]="{ item }">
                          <div v-html="formatTime(item.createdAt)" />
                        </template>
                      </v-data-table>
                    </td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="6" height="100%">
            <v-card height="100%">
              <v-card-title
                style="display: flex; justify-content: space-between;"
              >
                <div>Events</div>
                <v-btn text @click="goToEventHome">View All</v-btn>
              </v-card-title>
              <v-card-text>
                <div
                  v-for="event in events"
                  :key="event._id"
                  class="mb-4 d-flex"
                >
                  <div><v-img :src="event.cover" height="70" width="70" /></div>
                  <div
                    style="flex-grow: 1; padding-left: 10px; padding-right: 10px;"
                  >
                    <div
                      style="overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        line-clamp: 1;
                        -webkit-box-orient: vertical;
                        font-family: 'Poppins-SemiBold';
                        color: #482684;"
                      class="url-button"
                      @click.stop="goToEventDetails(event._id)"
                      @mousedown.stop
                    >
                      {{ event.title }}
                    </div>
                    <div class="limited-text">
                      {{ event.text }}
                    </div>
                  </div>
                  <div style="font-size: 12px;">
                    {{ getDate(event.createdAt) }}
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="6" height="100%">
            <v-card height="100%">
              <v-card-title class="d-flex justify-between">
                <div>Foods</div>
                <v-btn text @click="getFoodBarcodePDF">Get Summary</v-btn>
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :items="balances"
                  :headers="balanceHeader"
                  :items-per-page="3"
                  :footer-props="{
                    'items-per-page-options': [3, 5, 10, 15, -1],
                  }"
                >
                  <template v-slot:[`item.image`]="{ item }">
                    <div
                      class="ma-3"
                      style="width: 70px; max-width: 70px;"
                      v-if="item.food"
                    >
                      <v-img
                        :src="
                          item.food.image ? item.food.image : placeholderImage
                        "
                        :width="70"
                        :height="50"
                      />
                    </div>
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    {{ item.food ? item.food.name : "" }}
                  </template>
                  <template v-slot:[`item.serving_size`]="{ item }">
                    {{ item.food.serving_size }} {{ item.food.unit }}
                  </template>
                  <template v-slot:[`item.meals`]="{ item }">
                    {{ item.food.meals }}
                  </template>
                  <template v-slot:[`item.amount`]="{ item }">
                    <div v-if="item.editing">
                      <v-text-field v-model="item.amount" />
                    </div>
                    <div v-else>
                      {{ item.amount }}
                    </div>
                  </template>
                  <template v-slot:[`item.nutritions`]="{ item }">
                    <div v-if="item.food.fruits">
                      Fruits: {{ item.food.fruits }}
                    </div>
                    <div v-if="item.food.vegetables">
                      Vegetables: {{ item.food.vegetables }}
                    </div>
                    <div v-if="item.food.grains">
                      Grains: {{ item.food.grains }}
                    </div>
                    <div v-if="item.food.protein">
                      Protein: {{ item.food.protein }}
                    </div>
                    <div v-if="item.food.dairy">
                      Dairy: {{ item.food.dairy }}
                    </div>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div style="width: 120px;">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="onEditBalance(item)"
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon>
                              {{ item.editing ? "mdi-check" : "mdi-pencil" }}
                            </v-icon>
                          </v-btn>
                        </template>
                        Edit
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            @click="onDeleteBalance(item)"
                            v-bind="attrs"
                            v-on="on"
                            ><v-icon>mdi-close</v-icon></v-btn
                          >
                        </template>
                        Delete
                      </v-tooltip>
                    </div>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="6" md="6" height="100%">
            <v-card>
              <v-card-title>
                Freezer
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :items="freezers"
                  :headers="freezerHeader"
                  :items-per-page="5"
                >
                  <template v-slot:[`item.image`]="{ item }">
                    <editable-avatar
                      :image="item.image"
                      :editable="false"
                      :size="30"
                    />
                  </template>
                  <template v-slot:[`item.name`]="{ item }">
                    {{ item.first_name + " " + item.last_name }}
                  </template>
                  <template v-slot:[`item.serving_size`]="{ item }">
                    {{ item.food.serving_size }} {{ item.food.unit }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
            <v-card class="mt-6" @click="goToChat">
              <v-card-title>
                Chat
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <bottom-bar />
    <FoodPDFSummary v-if="pdfDialog" ref="barcode" />
    <confirm-delete-dialog
      :deleteDialog="deleteDialog"
      :onClose="onCloseDialog"
      :onConfirm="onConfirmDelete"
    />
    <client-order-requests-dialog
      :dialog="requestDialog"
      :onClose="onCloseDialog"
      :onSelected="onSelectedRequest"
      v-if="requestDialog"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "../../components/core/EditableAvatar.vue";
// import FreezerDistributionDialog from "./FreezerDistributionDialog.vue";
import dateFormat from "dateformat";
import BottomBar from "../../components/core/BottomBar.vue";
import FoodPDFSummary from "@/components/admin/FoodPDFSummary.vue";
import ConfirmDeleteDialog from "@/components/ConfirmDeleteDialog.vue";
import ClientOrderRequestsDialog from "./ClientOrderRequestsDialog";
import io from "socket.io-client";
var socket = io.connect(process.env.VUE_APP_SOCKET_URL);

export default {
  name: "Home",
  components: {
    EditableAvatar,
    BottomBar,
    FoodPDFSummary,
    ConfirmDeleteDialog,
    ClientOrderRequestsDialog,
  },
  data() {
    return {
      loading: false,
      balanceHeader: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Nutritions", value: "nutritions" },
        { text: "Serving size", value: "serving_size" },
        { text: "Number of serving", value: "meals" },
        { text: "Amount", value: "amount" },
        { text: "Actions", value: "actions" },
      ],
      freezerHeader: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Type", value: "type" },
        { text: "Email", value: "email" },
        // { text: "phone", value: "phone" },
        // { text: "Actions", value: "actions" },
      ],
      distributionHeader: [
        { text: "", value: "image", sortable: false, groupable: false },
        { text: "Client id", value: "client_id" },
        { text: "Name", value: "name", groupable: false },
        { text: "Food", value: "food" },
        { text: "Amount", value: "amount", groupable: false },
        { text: "Date", value: "createdAt" },
        { text: "", value: "data-table-expand" },
      ],
      subHeaders: [
        { text: "", value: "image", sortable: false },
        { text: "Name", value: "name" },
        { text: "Serving size", value: "serving_size" },
        { text: "Number of serving", value: "meals" },
        { text: "Nutritions", value: "nutritions" },
        { text: "Amount", value: "amount" },
        { text: "Date", value: "date" },
        // { text: "Actions", value: "actions" },
      ],
      placeholderImage: require("@/assets/default_image.png"),
      selected: null,
      distributionDialog: false,
      deleteDialog: false,
      requestDialog: false,
      center: null,
      expanded: [],
      historyData: {},
      loadingEvents: false,
      events: [],
      pdfDialog: false,
      balances: [],
    };
  },
  methods: {
    ...mapActions({
      createNewConversation: "chat/createNewConversation",
      getFreezers: "freezer/fetchAllFreezers",
      fetchAllFreezerBalances: "freezer/fetchAllFreezerBalances",
      fetchAllFoods: "food/fetchAllFoods",
      getFood: "food/getFood",
      fetchAllDistributions: "distribution/fetchAllDistributions",
      fetchAllEvents: "event/fetchAllEvents",
      updateBalance: "freezer/editBalance",
      deleteBalance: "freezer/deleteBalance",
      fetchAllNotifications: "notification/fetchAllNotifications",
      fetchAllRequests: "request/fetchAllRequests",
    }),
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    convertTZ(date) {
      if (!date) {
        return new Date(
          new Date().toLocaleString("en-US", {
            timeZone: "America/New_York",
          })
        );
      }
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: "America/New_York",
          }
        )
      );
    },
    formatTime(date) {
      return dateFormat(this.convertTZ(date), "hh:MM tt");
    },
    getDateFromIndex(_id) {
      var date = _id.substring(6, 16);
      return date;
    },
    onDistribute() {
      // this.distributionDialog = true;
      this.$router.push({ name: "freezer-distribution" });
    },
    onCloseDialog() {
      this.distributionDialog = false;
      this.deleteDialog = false;
      this.requestDialog = false;
    },
    onDistributed() {
      this.distributionDialog = false;
    },
    goToChat() {
      this.createNewConversation()
        .then((res) => {
          this.$router.push({
            name: "chat-details",
            params: { id: res._id },
          });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data.message);
          } else {
            console.log(error);
          }
        });
    },
    goToHistory() {
      this.$router.push({
        name: "partner-distributions",
      });
    },
    editProfile() {
      this.$router.push({
        name: "partner-profile",
      });
    },
    loadEvents() {
      this.loadingEvent = true;
      this.fetchAllEvents({ limit: 4 })
        .then((res) => {
          this.loadingEvent = false;
          this.events = res;
        })
        .catch((error) => {
          this.loadingEvent = false;
          console.log(error.response.data.message);
        });
    },
    getDate(dateStr) {
      return dateFormat(this.convertTZ(dateStr), "mm/dd/yy");
    },
    goToEventHome() {
      this.$router.push({ name: "event-home" });
    },
    goToEventDetails(_id) {
      this.$router.push({ name: "event-details", query: { _id } });
    },
    getFoodBarcodePDF() {
      if (this.pdfDialog) {
        this.$refs.barcode.generateReport();
      }
      this.pdfDialog = true;
    },
    onEditBalance(item) {
      this.balances = this.balances.map((b) => {
        if (b._id == item._id) {
          if (b.editing) {
            this.updateBalance({ _id: item._id, amount: item.amount })
              .then(() => {
                b.amount = item.amount;
              })
              .catch((error) => {
                console.log(error);
              });

            return { ...b, editing: false };
          }
          return { ...b, editing: true };
        }
        return b;
      });
    },
    onDeleteBalance(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      if (!this.selected) return;
      this.balances = this.balances.filter((b) => {
        if (b._id == this.selected._id) {
          this.deleteBalance({ _id: this.selected._id })
            .then(() => {
              console.log("deleted");
            })
            .catch((error) => {
              console.log(error);
            });

          return false;
        }
        return true;
      });
    },
    onSelectedRequest(request) {
      this.requestDialog = false;
      this.$router.push({ name: "freezer-distribution", query: { request } });
    },
  },
  computed: {
    ...mapGetters({
      freezers: "freezer/getAllFreezers",
      allBalances: "freezer/getAllBalances",
      profile: "auth/getProfile",
      distributions: "distribution/getAllDistributions",
      requests: "request/getAllRequests",
    }),
  },
  watch: {
    allBalances(newValue) {
      this.balances = [...newValue];
    },
    profile(newValue) {
      if (newValue._id) {
        socket.on(`client_request_${newValue._id}`, () => {
          // console.log("client_request", data);
          this.fetchAllNotifications();
          this.requestDialog = true;
        });
      }
    },
  },
  created() {
    this.loading = true;
    this.fetchAllFreezerBalances()
      .then(() => {
        this.loading = false;
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
    this.getFreezers().catch((error) => {
      console.log(error.response.data.message);
    });
    this.fetchAllDistributions().catch((error) => {
      console.log(error.response.data.message);
    });
    this.loadEvents();
    this.balances = [...this.allBalances];
    this.fetchAllRequests();

    if (this.profile._id) {
      socket.on(`client_request_${this.profile._id}`, () => {
        // console.log("client_request", data);
        this.fetchAllNotifications();
        this.requestDialog = true;
      });
    }
  },
};
</script>
<style scoped>
.home_screen {
  background-color: #f4f9ff;
  margin: 0;
  padding: 0;
  min-height: calc(100vh - 120px);
}
.list-body {
  width: 100%;
  min-width: 200px;
  margin-left: 60px;
  margin-right: 20px;
  align-items: center;
  margin-bottom: 30px;
}
.item_title {
  color: #423f63;
  font-family: "Poppins-Bold";
  font-size: 20px;
}
.count_label {
  color: #747474;
  font-family: "Poppins-SemiBold";
  font-size: 16px;
}
.users_image_section {
  padding-bottom: 20px;
  padding-top: 30px;
  /* padding-left: 40px; */
  justify-content: center;
  max-width: 200px;
}
.on-hover {
  background-color: #f3e9ff !important;
}
.limited-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  color: #423f63;
  font-size: 10px;
}
</style>
